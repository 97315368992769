import { create } from 'zustand';

import {
    FORM_COMPLETE_STEP,
    FORM_CURRENT_STEP,
    FORM_UPCOMING_STEP,
} from '@src/constants/form401k';

const useFormStore = create((set) => ({
    clear: () => set(useFormStore.getInitialState()),
    form_ready: false,
    setFormReady: (ready) => set(() => ({ form_ready: ready })),
    currentStep: 'personalDetails',
    setCurrentStep: (step) => set(() => ({ currentStep: step })),
    steps: [
        {
            value: 'personalDetails',
            name: 'Personal Details',
            short: 'Personal',
        },
        {
            value: 'identityDetails',
            name: 'Identity Details',
            short: 'Identity',
        },
        {
            value: 'employerDetails',
            name: 'Employer Details',
            short: 'Employer',
        },
        {
            value: 'planDetails',
            name: 'Plan Details',
            short: 'Plan',
        },
        {
            value: 'review',
            name: 'Review page',
            short: 'Review',
            hidden: true,
        },
        {
            value: 'payment',
            name: 'Checkout',
            short: 'Checkout',
        },
    ],
    setStepUp: () => set((state) => {
        const { currentStep, steps } = state;
        const newSteps = [...steps];
        const stepIdx = newSteps.findIndex((s) => s.value === currentStep);
        newSteps[stepIdx].status = FORM_COMPLETE_STEP;
        newSteps[stepIdx + 1].status = FORM_CURRENT_STEP;

        // Scroll to top of the page each time when the step changes
        window.scrollTo({ top: 0 });

        return {
            steps: newSteps,
            currentStep: newSteps[stepIdx + 1].value,
        };
    }),
    setStepDown: () => set((state) => {
        const { currentStep, steps } = state;
        const newSteps = [...steps];
        const stepIdx = newSteps.findIndex((s) => s.value === currentStep);
        newSteps[stepIdx].status = FORM_UPCOMING_STEP;
        newSteps[stepIdx - 1].status = FORM_CURRENT_STEP;

        // Scroll to top of the page each time when the step changes
        window.scrollTo({ top: 0 });

        return {
            steps: newSteps,
            currentStep: newSteps[stepIdx - 1].value,
        };
    }),
    personalDetails: {
        address01: '',
        address02: '',
        city: '',
        control_group: false,
        different_mailing_address: false,
        DOB: '',
        email: '',
        first_name: '',
        gender: '',
        last_name: '',
        mailing_address01: '',
        mailing_address02: '',
        mailing_city: '',
        mailing_postal_code: '',
        mailing_state_long: '',
        mailing_state: '',
        married: false,
        middle_name: '',
        occupation: '',
        phone: '',
        postal_code: '',
        spouse_email: '',
        spouse_first_name: '',
        spouse_last_name: '',
        ssn: '',
        state_long: '',
        state: '',
        step_personal_details: false,
        title: '',
    },
    identityDetails: {
        id_expiry_date: '',
        id_photo_number: '',
        id_photo_picture: '',
        id_photo_picture2: '',
        id_state: '',
        id_type: '',
        step_identity_details: false,
    },
    planDetails: {
        bank_terms_agree: false,
        isSelectedSignature: false,
        plan_id: '',
        plan_ein: '',
        plan_establish_date: null,
        loan_interest_rate: '',
        plan_loan: true,
        plan_name: '',
        plan_roth_roll: true,
        plan_roth: true,
        plan_sign_date: '',
        plan_signature: '',
        plan_signer_first_name: '',
        plan_signer_last_name: '',
        plan_signer_title: '',
        step_plan_details: false,
    },
    signerDetails: {
        participant_signature: '',
        participant_signer_first_name: '',
        participant_signer_last_name: '',
        participant_signer_title: '',
        participant_sign_date: '',
        step_signer_details: false,
    },
    employerDetails: {
        different_mailing_address: false,
        employer_address01: '',
        employer_address02: '',
        employer_city: '',
        employer_ein: '',
        employer_email: '',
        employer_fiscal_year_end: '',
        employer_fiscal_year_end_date: '',
        employer_incorp_date: '',
        employer_incorporation_state: '',
        employer_mailing_address01: '',
        employer_mailing_address02: '',
        employer_mailing_city: '',
        employer_mailing_postal_code: '',
        employer_mailing_state_long: '',
        employer_mailing_state: '',
        employer_naics_code_description: '',
        employer_naics_code: '',
        employer_name: '',
        employer_phone: '',
        employer_postal_code: '',
        employer_state_long: '',
        employer_state: '',
        employer_website: '',
        entity_type: '',
        step_employer_details: false,
    },
    bankDetails: {
        bank_account_monthly_ach_activity_international: '',
        bank_account_monthly_activity_domestic: '',
        bank_account_monthly_activity_international: '',
        bank_account_monthly_wire_activity_international: '',
        bank_assets_type: '',
        bank_income: '',
        bank_source_of_funds: '',
        bank_terms_agree: false,
        bankPin: '',
        isSelectedSignature: false,
        plan_sign_date: '',
        plan_signature: '',
        plan_signer_first_name: '',
        plan_signer_last_name: '',
        plan_signer_title: '',
        step_bank_details: false,
    },
    setPersonalDetails: (data) => set(() => ({ personalDetails: data })),
    setPlanDetails: (data) => set(() => ({ planDetails: data })),
    setSignerDetails: (data) => set(() => ({ signerDetails: data })),
    setEmployerDetails: (data) => set(() => ({ employerDetails: data })),
    setBankDetails: (data) => set(() => ({ bankDetails: data })),
    setIdentityDetails: (data) => set(() => ({ identityDetails: data })),
    bankJson: null,
    setBankJson: (data) => set(() => ({ bankJson: data })),
    configureParticipantSteps: () => set(({ steps }) => {
        const participantSteps = steps
            .filter(({ value }) => value !== 'employerDetails' && value !== 'payment')
            .map((step) => {
                if (step.value !== 'planDetails') return step;
                return ({
                    value: 'signerDetails',
                    name: 'Signer Details',
                    short: 'Signer',
                });
            });

        return {
            steps: participantSteps,
        };
    }),
}));

export default useFormStore;
